



















import { Vue, Prop, Component } from 'vue-property-decorator';
import InsProductItem from '@/components/business/mobile/product/InsProductItem.vue';
import { Promotion } from '@/model/promotionResult';
@Component({ components: { InsProductItem } })
export default class EdProductPromotion extends Vue {
  private promotion: Promotion = new Promotion();
  @Prop({ default: 2 }) column!: number;
  @Prop({ default: 100 }) w!: number;
  @Prop({ default: true }) border!: boolean;
  @Prop({ default: true }) top!: boolean;
  @Prop({ default: true }) button!: boolean;
  @Prop({ default: true }) private code!: boolean;
  @Prop({ default: '' }) private styla!: string;
  @Prop() page!:string;
  @Prop() position!:string;
  created () {
    if (!this.page) throw new Error('推廣頁面不可爲空');
    if (!this.position) throw new Error('推廣位置不可爲空');
    this.$Api.promotion.getPromotion(this.page, this.position).then(result => {
      this.promotion = result.Promotion;
    });
  }
}
